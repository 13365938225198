import { trackerMain } from '../../../libraries/tracker';

export const COACHMARK_COOKIE = 'coachmark-hol-global';

export const COACHMARKS = [
  {
    cookie: 'bookmark-button-coachmark',
    triggerId: 'bookmark-tooltip',
    viewports: ['desktop', 'mobile'],
    shouldLogin: true,
    packageAccess: [
      'member-standard',
      'member-professional',
      'member-professional-plus',
    ],
    // TODO: only position bottom-left is stable, you should modify the other position later
    position: 'bottom-left',
    offset: {
      x: 33,
      y: 0,
    },
    title: 'Jelajahi Fitur Bookmark',
    description:
      'Simpan dokumen ini untuk bisa dibaca kembali dan buat daftar bacaan Anda sendiri!',
    next: 'Oke',
    onClickNext: () => {
      trackerMain({
        snowplow: [
          'coachmark_bookmark_click_oke',
          'button - count button coachmark click',
          '',
          '',
          '',
        ],
        ga4: [
          'coachmark_bookmark_click_oke',
          '',
          '',
          'button',
          'count button coachmark click',
          '',
          '',
          '',
        ],
      });
    },
  },
];
