/* eslint-disable react/prop-types */
import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { ApolloProvider } from '@apollo/client';
import dynamic from 'next/dynamic';
import client from '../libraries/graphql';
import CoachmarkV2 from '../components/coachMark/v2';
import { AdditionalMenuContextProvider } from '../context/additionalHeaderContext';
import CoachmarkV2Provider from '../components/coachMark/v2/CoachmarkV2Provider';

// WebVitals reporting depends on document, so this needs to be lazy-imported to avoid error
const WebPerformanceObserver = dynamic(
  () => import('../components/Observability'),
  { ssr: false },
);

export default function MyApp({ Component, pageProps }) {
  return (
    <>
      <ToastProvider
        placement="top-center"
        autoDismiss
        autoDismissTimeout="3000"
      >
        <ApolloProvider client={client}>
          <CoachmarkV2Provider>
            <AdditionalMenuContextProvider>
              <Component {...pageProps} />
            </AdditionalMenuContextProvider>
            <CoachmarkV2 {...pageProps} />
          </CoachmarkV2Provider>
        </ApolloProvider>
      </ToastProvider>
      <WebPerformanceObserver />
    </>
  );
}
