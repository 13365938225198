/* eslint-disable no-undef */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { COACHMARKS, COACHMARK_COOKIE } from './constants';
import stylesheet from './CoachMarkV2.scss';
import useWindowSize from '../../../hooks/useWindowSize';
import { getCookie } from '../../../libraries/session';
import { useCoachmarkV2Context } from './CoachmarkV2Provider';

function setCookieWithExpire(param, expiry) {
  const date = new Date();
  // expiry number is representing days
  date.setTime(date.getTime() + expiry * 24 * 60 * 60 * 1000);
  document.cookie = `${param.key}=${
    param.value
  };expires=${date.toUTCString()};path=/`;
}

let observer = null;

function CoachmarkV2(props) {
  const { dashboardProCoachmarkTriggered } = useCoachmarkV2Context();
  const { user_identity: user } = props;
  const s = useWindowSize();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const ref = useRef(null);
  const [currentCoachmark, setCurrentCoachmark] = useState(0);
  const [isShowCoachmark, setIsShowCoachmark] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [defisitPosition, setDefisitPosition] = useState({ x: 0, y: 0 });

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        return;
      }

      const obs = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          if (document.querySelector(selector)?.offsetLeft) {
            obs.disconnect();
            resolve(document.querySelector(selector));
          }
        }
      });

      obs.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  const coachmark = useMemo(() => COACHMARKS[currentCoachmark], [
    currentCoachmark,
  ]);

  useEffect(() => {
    if (!isClient || typeof window === 'undefined') return;
    if (!dashboardProCoachmarkTriggered) return;
    if (!coachmark) return;

    const doCoachmark = () => {
      // TODO: buat kalau dalam waktu 5 detik element tidak muncul, maka tidak menampilkan coachmark dan lanjut ke coachmark selanjutnya

      if (
        coachmark.shouldLogin &&
        !coachmark.packageAccess.includes(user?.groups)
      ) {
        setCurrentCoachmark((prev) => prev + 1);
        return;
      }

      waitForElm(`#${coachmark.triggerId}`).then((elm) => {
        const manipulateXAndY = () => {
          const coachmarkWidth = ref?.current?.offsetWidth || 0;
          const coachmarkHeight = ref?.current?.offsetHeight || 0;
          const halfCoachmarkWidth = ref.current?.offsetWidth
            ? ref.current?.offsetWidth / 2
            : 160;
          const halfCoachmarkHeight = ref.current?.offsetHeight
            ? ref.current?.offsetHeight / 2
            : 0;
          const width = elm.offsetWidth;
          const height = elm.offsetHeight;
          let x = 0;
          let y = 0;
          switch (coachmark.position) {
            case 'bottom': {
              x = elm.offsetLeft - width / 2;
              y =
                elm.getBoundingClientRect().top + window.scrollY + height + 16;
              break;
            }
            case 'bottom-left': {
              x =
                window.screen.availWidth -
                (window.screen.availWidth -
                  (elm.getBoundingClientRect().left + elm.offsetWidth)) -
                coachmarkWidth +
                (coachmark?.offset?.x || 0);
              y =
                elm.getBoundingClientRect().top +
                window.scrollY +
                height +
                16 +
                (coachmark?.offset?.y || 0);
              break;
            }
            case 'top': {
              x = elm.offsetLeft - halfCoachmarkWidth + width / 2;
              y =
                elm.getBoundingClientRect().top +
                window.scrollY -
                coachmarkHeight -
                16;
              break;
            }
            case 'left': {
              x = elm.offsetLeft - coachmarkWidth - 16;
              y =
                elm.getBoundingClientRect().top +
                window.scrollY -
                halfCoachmarkHeight +
                height / 2;
              break;
            }
            case 'right': {
              x = elm.offsetLeft + width + 16;
              y =
                elm.getBoundingClientRect().top +
                window.scrollY -
                halfCoachmarkHeight +
                height / 2;
              break;
            }
            default: {
              break;
            }
          }
          setPosition(() => {
            const defisitX = x < 0 ? x : 0;
            const defisitY = y < 0 ? y : 0;
            setDefisitPosition({ x: defisitX, y: defisitY });
            return { x: x - defisitX, y: y - defisitY };
          });

          setTimeout(() => {
            setIsShowCoachmark(true);
          }, 300);
        };

        observer = new MutationObserver(() => {
          manipulateXAndY();
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });

        manipulateXAndY();
      });
    };

    const cookie = getCookie(COACHMARK_COOKIE);
    if (cookie) {
      const parsedCookies = Array.isArray(JSON.parse(cookie))
        ? JSON.parse(cookie)
        : [];
      if (parsedCookies.includes(coachmark.cookie)) {
        setCurrentCoachmark((prev) => prev + 1);
      } else {
        doCoachmark();
      }
    } else {
      doCoachmark();
    }
  }, [
    dashboardProCoachmarkTriggered,
    coachmark,
    isClient,
    user?.groups,
    s.width,
    s.height,
  ]);

  function handleNextCoachmark() {
    if (!coachmark || !isClient) return;
    const cookie = getCookie(COACHMARK_COOKIE);
    const parsedCookies =
      cookie && Array.isArray(JSON.parse(cookie)) ? JSON.parse(cookie) : [];

    parsedCookies.push(coachmark.cookie);
    setCookieWithExpire(
      { key: COACHMARK_COOKIE, value: JSON.stringify(parsedCookies) },
      365,
    );
    observer.disconnect();
    setIsShowCoachmark(false);
    setCurrentCoachmark((prev) => prev + 1);
  }

  const arrowStyle = useMemo(() => {
    if (!coachmark || !isClient || typeof window === 'undefined') return {};
    const halfCoachmarkWidth = ref.current?.offsetWidth
      ? ref.current?.offsetWidth / 2
      : 160;
    const halfCoachmarkHeight = ref.current?.offsetHeight
      ? ref.current?.offsetHeight / 2
      : 0;

    let obj = {};

    switch (coachmark.position) {
      case 'bottom':
        obj = {
          transform: 'translate(25%, 50%) rotate(45deg)',
          top: (coachmark?.offset?.y || 0) - 16,
          left: (coachmark?.offset?.x || 0) + (halfCoachmarkWidth - 10),
        };
        break;
      case 'bottom-left':
        obj = {
          transform: 'translate(25%, 50%) rotate(45deg)',
          top: (coachmark?.offset?.y || 0) - 16,
          right: (coachmark?.offset?.x || 0) + 16 - 5,
        };
        break;
      case 'top':
        obj = {
          transform: 'translate(25%, 50%) rotate(45deg)',
          bottom: (coachmark?.offset?.y || 0) + 8,
          left: (coachmark?.offset?.x || 0) + (halfCoachmarkWidth - 10),
        };
        break;
      case 'left':
        obj = {
          transform: 'translate(50%, 25%) rotate(45deg)',
          top: (coachmark?.offset?.y || 0) + (halfCoachmarkHeight - 16),
          right: (coachmark?.offset?.x || 0) + 8,
        };
        break;
      case 'right':
        obj = {
          transform: 'translate(50%, 25%) rotate(45deg)',
          top: (coachmark?.offset?.y || 0) + (halfCoachmarkHeight - 16),
          left: (coachmark?.offset?.x || 0) - 16,
        };
        break;
      default:
        obj = {};
        break;
    }
    if (typeof obj.top === 'number') {
      obj.top = `${obj.top - defisitPosition.y}px`;
    }
    if (typeof obj.bottom === 'number') {
      obj.bottom = `${obj.bottom - defisitPosition.y}px`;
    }
    if (typeof obj.left === 'number') {
      obj.left = `${obj.left - defisitPosition.x}px`;
    }
    if (typeof obj.right === 'number') {
      obj.right = `${obj.right - defisitPosition.x}px`;
    }
    return obj;
  }, [
    coachmark,
    ref.current?.offsetHeight,
    ref.current?.offsetWidth,
    defisitPosition.x,
    defisitPosition.y,
  ]);

  if (!isClient || typeof window === 'undefined') return null;
  if (currentCoachmark >= COACHMARKS.length) return null;

  // return null;

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <div
        ref={ref}
        className={`coachmark-v2 ${isShowCoachmark ? 'active' : ''}`}
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
        }}
      >
        <div className="coachmark-v2__content">
          <div className="coachmark-v2__arrow" style={arrowStyle} />

          {!!coachmark.title && (
            <p className="coachmark-v2__title">{coachmark.title}</p>
          )}
          {!!coachmark.description && (
            <p className="coachmark-v2__description">{coachmark.description}</p>
          )}
          <div className="coachmark-v2__action">
            <Button
              outline
              color="primary"
              onClick={() => {
                handleNextCoachmark();
                if (coachmark.onClickNext) {
                  coachmark.onClickNext();
                }
              }}
            >
              {coachmark.next ?? 'Oke'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

CoachmarkV2.defaultProps = {
  user_identity: {},
};

CoachmarkV2.propTypes = {
  user_identity: PropTypes.shape({
    groups: PropTypes.string.isRequired,
  }),
};

export default CoachmarkV2;
