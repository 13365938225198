import React, { createContext, useContext, useState } from 'react';

const AdditionalMenuContext = createContext({
  activeMenu: '',
});

export const AdditionalMenuContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState('');

  return (
    <AdditionalMenuContext.Provider value={{ activeMenu, setActiveMenu }}>
      {children}
    </AdditionalMenuContext.Provider>
  );
};

export const useAdditionalMenuContext = () => useContext(AdditionalMenuContext);
