import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCookie } from '../../../libraries/session';

const CoachmarkV2Context = createContext();

function CoachmarkV2Provider({ children }) {
  const [
    dashboardProCoachmarkTriggered,
    setDashboardProCoachmarkTriggered,
  ] = useState(false);

  useEffect(() => {
    setDashboardProCoachmarkTriggered(!!getCookie('dashboardProCoachmark'));
  }, []);

  return (
    <CoachmarkV2Context.Provider
      value={{
        dashboardProCoachmarkTriggered,
        setDashboardProCoachmarkTriggered,
      }}
    >
      {children}
    </CoachmarkV2Context.Provider>
  );
}

CoachmarkV2Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCoachmarkV2Context = () => {
  const store = useContext(CoachmarkV2Context);
  if (!store) {
    throw new Error('cannot using useCoachmarkV2Context');
  }
  return store;
};

export default CoachmarkV2Provider;
