import {
  ApolloClient, ApolloLink, InMemoryCache, HttpLink,
} from 'apollo-boost';
const getConfig = require('next/config').default;

// retrieve configuration
const { publicRuntimeConfig } = getConfig();
const { API_BASE_URL, TOKEN_PUBLIC } = publicRuntimeConfig;
const httpLink = new HttpLink({ uri: `${API_BASE_URL}/v1/kamus` });
// const httpLink = new HttpLink({ uri: `${GRAPHQL_BASE_URL}` });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = operation?.variables?.token || TOKEN_PUBLIC;
  
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
